export default {
  environment: "production",
  server: {
    host: "https://opera-server.sumit-labs.com",
    auth: "auth",
    users: "users",
    jobs: "jobs",
    attachments: "attachments",
    uploads: "uploads",
    tags: "tags",
    files: "files",
    export: "export",
    clients: "clients",
    docxTemplates: "docxTemplates",
    assignments: "assignments",
    validationPresets: "validationPresets",
    communityPayments: "communityPayments",
    invoices: "invoices",
  },
  firebase: {
    clientId:
      "136073597195-uealeull2afketl3q2pnjceshv8jecg0.apps.googleusercontent.com",
    apiKey: "AIzaSyAzaed6HrVfp_hrxcBO01zD4EOG7U9OITY",
    authDomain: "sumit-249611.firebaseapp.com",
    projectId: "sumit-249611",
    storageBucket: "sumit-249611.appspot.com",
    messagingSenderId: "136073597195",
    appId: "1:136073597195:web:3c9866d32d3be0ffa8120e",
    measurementId: "G-S1WFT6CQBS",
    tenantId: "platforms-ds2lb",
  },
  featureFlags: {
    apiKey:
      "opera:production.96df3bc685908d45f0af9612ea576fc1e100f1791163e43b8102052a",
  },
  cloudinary: {
    cloud_name: "df9neab8j",
    upload_preset: "ejh79zy5",
    api_key: "724134266828553",
  },
};
